import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  pl: {
    translation: {
      shared: {
        achievements: {
          social:"Osiągnięcia Społeczne",
          first_circle_title: "Pierwszy Krąg",
          first_circle_description:
            "Osiągnąłeś 5 przyjaciół! Budowanie połączeń to świetny początek.",
          growing_network_title: "Rosnąca Sieć",
          growing_network_description:
            "15 przyjaciół i rośnie! Twoja sieć społeczna się rozrasta.",
          community_builder_title: "Budowniczy Społeczności",
          community_builder_description:
            "Z 50 przyjaciółmi łączysz się z dynamiczną społecznością!",
          social_star_title: "Gwiazda Społeczności",
          social_star_description:
            "Osiągnąłeś 100 przyjaciół! Twoje połączenia nadal rosną — tak trzymaj!",
          network_champion_title: "Mistrz Sieci",
          network_champion_description:
            "250 przyjaciół! Jesteś prawdziwym łącznikiem społecznym i inspiracją dla innych.",
        },
        navbar: {
          lightMode: "Tryb dzienny",
          nightMode: "Tryb nocny",
          logIn: "Zaloguj się",
          logout: "Wyloguj",
        },
        friends: {
          friends: "Znajomi",
          addFriend: "Dodaj znajomego",
          joinedPlatform: "Dołączył do platformy",
          usersToInvite: "Użytkownicy do zaproszenia",
          pendingUsers: "Oczekujący użytkownicy",
          noFriends: "Brak znajomych do wyświetlenia",
          noPending: "Brak oczekujących użytkowników do wyświetlenia",
          sendInvitation: "Wyślij zaproszenie",
          newFriend: "Szukaj użytkowników",
          invitationText: "chce być twoim znajomym. Akceptujesz?",
          accept: "Akceptuj",
          reject: "Odrzuć",
        },
        profile: {
          removeFriend: "Usuń znajomego",
          daysOnPlatform: "Dni na platformie",
          birth: "Data urodzenia",
        },
        actions: {
          delete: "Usuń",
          edit: "Edytuj",
          close: "Zamknij",
          add: "Dodaj",
        },
        login: {
          loginTitle: "Logowanie",
          loginButton: "Zaloguj",
          email: "Email",
          password: "Hasło",
          noAccount: "Nie mam jeszcze konta",

          users: "Użytkowników na platformie",
          backgroundText: "TRENUJ I BIEGAJ",
        },
        register: {
          registerTitle: "Rejestracja",
          registerButton: "Zarejestruj",
          email: "Email",
          password: "Hasło",
          account: "Mam już konto",
          birth: "Data urodzenia",
          profilePhoto: " Zdjęcie profilowe",
          nickname: "Nick",
          description: "Opis",
          noFile: "Brak pliku",
        },
        chart: {
          run: "Bieganie",
          gym: "Trening siłowy",
          trainings: "Treningi",
          months: "Miesiące",
          workouts: "Treningi w ostatnich miesiącach",
        },
        posts: {
          lastPosts: "Ostatnie posty użytkowników",
          noPosts: "Brak postów do wyświetlenia",
          new: "Nowy",
        },
      },
      run: {
        achievements: {
          run:"Osiągnięcia Biegowe",
          marathon_achiever_title: "Mistrz Maratonu",
          marathon_achiever_description: "Ukończyłeś maraton!",
          ultra_runner_title: "Ultra Biegacz",
          ultra_runner_description: "Ukończyłeś ultramaraton!",
          endurance_master_title: "Mistrz Wytrzymałości",
          endurance_master_description: "Osiągnąłeś 1000 kilometrów!",
          long_distance_veteran_title: "Weteran Długich Dystansów",
          long_distance_veteran_description: "Osiągnąłeś 5000 kilometrów!",
          distance_legend_title: "Legenda Dystansu",
          distance_legend_description: "Osiągnąłeś 10 000 kilometrów!",
        },
        general: {
          averagePulse: "Średnie tętno",
          distance: "Dystans",
          duration: "Czas trwania",
          rating: "Ocena",
          note: "Notatka",
          type: "Typ",
          trainings: "Treningi",
          category: "Kategorie",
        },
        categories: {
          long_run: "Długi Bieg",
          easy_run: "Lekki Bieg",
          interval: "Interwał",
          regeneration: "Regeneracja"
        },
        dashboard: {
          title: "BIEGAJ CODZIENNIE",
          description:
            "Bieganie to nie tylko doskonały sposób na poprawę kondycji, ale również na zrelaksowanie umysłu. Każdy krok, który robisz, wzmacnia Twoje ciało, zwiększa wydolność serca i poprawia nastrój. Niezależnie od tego, czy biegasz dla zdrowia, formy, czy po prostu dla przyjemności, regularne bieganie przyczynia się do poprawy ogólnego samopoczucia. Nie potrzebujesz specjalistycznego sprzętu – wystarczy para wygodnych butów i trochę motywacji. Zrób pierwszy krok i dołącz do grona tych, którzy odkryli radość płynącą z biegania!",
          newTraining: "Nowy trening",
          historyTraining: "Historia treningów",
          stats: "Statystyki",
          friends: "Znajomi",
        },
        profile: {
          showRecords: "Zobacz rekordy",
          addCategory: "Dodaj kategorię",
          showAchievements: "Zobacz osiągnięcia",
          addNewGoal: "Dodaj nowy cel",
          showGoals: "Zobacz cele",
          removeRoutine: "Usuń rutynę",
          allTrainings: "Wszystkie treningi",
          daysWithUs: "Dni z nami",
          email: "Email",
          birth: "Data urodzenia",
          social: "Społeczność",
        },
        historyTraining: {
          filtration: "Filtracja",
          category: "Kategoria",
          noTrainings: "Brak dostępnych treningów",
          addTraining: "Dodaj trening",
          all: "Wszytskie",
          loadMore: "Załaduj więcej",
          startDate: "Data rozpoczęcia",
          endDate: "Data zakończenia",
        },
        newTraining: {
          doTraining: "Zrób trening",
        },
        goals: {
          title: "Osiągnij swoje cele biegowe",
          description:
            "Odkryj swój pełny potencjał jako biegacz dzięki naszemu przewodnikowi po osiąganiu celów biegowych. Niezależnie od tego, czy dążysz do ukończenia pierwszego 5K, pokonania maratonu, czy poprawy swojego rekordu życiowego, ten przewodnik dostarcza narzędzi i strategii, których potrzebujesz. Dowiedz się, jak stworzyć skuteczny plan treningowy, utrzymać motywację i z pewnością pokonywać wyzwania. Pełen porad ekspertów, inspirujących historii i praktycznych wskazówek, ten przewodnik pomoże ci przekroczyć linię mety i świętować swoje biegowe osiągnięcia. Zrób pierwszy krok w kierunku swojego sukcesu biegowego już dziś!",
          createdDate: "Data utworzenia: ",
          finishDate: "Data zakończenia: ",
          noGoals: "Brak dostępnych celów do wyświetlenia",
          addGoal: "Dodaj nowe cele",
          completed: "Zakończony",
          notCompleted: "Niezakończony",
          inProgress: "W trakcie",
          dayLeft: "DZIEŃ POZOSTAŁ",
          daysLeft: "DNI POZOSTAŁO",
        },
        records: {
          personalRecords: "Rekordy personalne",
          longestDistance: "Najdłuższy dystans",
          highestAveragePulse: "Najwyższe średnie tętno",
          longestTraining: "Najdłuższy trening",
        },
        stats: {
          personalStats: "Statystyki osobiste",
          totalDistance: "Łączny dystans",
          averageRating: "Średnia ocena",
          averagePulse: "Średnie tętno",
          totalTime: "Łączny czas",
          week: "Tydzień",
          month: "Miesiąć",
          year: "Rok",
          all: "Wszystko",
        },
        chart: {
          noData: "Brak dostępnych danych",
          averagePulseTitle: "Średnie tętna z niedawnych treningów",
          distanceTitle: "Dystans z ostatnich treningów",
          durationTitle: "Czas trwania ostatnich treningów",
          ratingTitle: "Histogram ocen treningów",
          runTypesTitle: "Rodzaje biegów podczas ostatnich treningów",
        },
      },
      gym: {
        exercises: {
          bench_press: "Wyciskanie na ławce",
          chest_dips: "Dipy na klatkę piersiową",
          chin_ups: "Podciąganie na drążku (podchwytem)",
          bent_over_barbell_row: "Wiosłowanie sztangą w opadzie",
          barbell_biceps_curl: "Uginanie ramion ze sztangą",
          dumbbell_biceps_curl: "Uginanie ramion z hantlami",
          cable_triceps_pushdown: "Prostowanie ramion na wyciągu (triceps)",
          face_pull: "Face pull",
          barbell_back_squat: "Przysiad ze sztangą z tyłu",
          sit_ups: "Brzuszki",
          lat_pulldown: "Ściąganie drążka do klatki",
          dumbbell_lateral_raise: "Unoszenie hantli na boki",
          incline_dumbbell_curl: "Uginanie ramion z hantlami na ławce skośnej",
          triceps_cable_kick_back: "Wykroki tricepsa na wyciągu",
          dumbbell_bench_press: "Wyciskanie hantli na ławce",
          incline_dumbbell_bench_press: "Wyciskanie hantli na ławce skośnej",
          incline_bench_press: "Wyciskanie na ławce skośnej",
          dumbbell_flys: "Rozpiętki z hantlami",
          butterfly_machine: "Maszyna do rozpiętek (butterfly)",
          standing_cable_fly: "Rozpiętki na wyciągu stojąc",
          push_up: "Pompki",
          seated_cable_row: "Wiosłowanie na wyciągu siedząc",
          pull_up: "Podciąganie na drążku",
          standing_dumbbell_curl: "Uginanie ramion z hantlami stojąc",
          standing_hammer_curl: "Uginanie ramion z hantlami (młotkowo)",
          spider_curl: "Uginanie ramion w oparciu (spider curl)",
          dumbbell_french_press: "Francuskie wyciskanie hantli",
          straight_bar_tricep_extension:
            "Prostowanie ramion ze sztangą (triceps)",
          leg_extension: "Wydłużanie nóg na maszynie",
          dumbbell_bulgarian_split_squat: "Bułgarski wykrok z hantlami",
          dumbbell_romanian_deadlift: "Martwy ciąg rumuński z hantlami",
          walking_lunges: "Wykroki chodzone",
        },
        categories: {
          All: "Wszystkie",
          Chest: "Klatka",
          Back: "Plecy",
          Biceps: "Bicepsy",
          Triceps: "Tricepsy",
          Shoulders: "Barki",
          Abs: "Brzuch",
          Legs: "Nogi",
        },
        achievements: {
          gym:"Osiągnięcia Siłowe",
          first_steps_title: "Pierwsze Kroki",
          first_steps_description:
            "Gratulacje za ukończenie 5 treningów! Każda podróż zaczyna się od jednego kroku, a Ty już zrobiłeś kilka. Kontynuuj w tym tempie!",
          building_the_habit_title: "Budowanie Nawyków",
          building_the_habit_description:
            "Świetna robota! Ukończyłeś 20 treningów, pokazując zaangażowanie i determinację. Budujesz silny nawyk — trzymaj się tego kursu!",
          centurion_title: "Centurion",
          centurion_description:
            "Niesamowite osiągnięcie! Z 100 treningami na koncie, jesteś prawdziwym wojownikiem fitnessu. Twoja ciężka praca i wytrwałość są inspirujące!",
        },
        general: {
          page: "Strona",
          next: "Następna",
          previous: "Poprzednia",
          reps: "Powtórzenia",
          weight: "Ciężar",
          volume: "Objętość",
          duration: "Czas trwania",
          rating: "Ocena",
          note: "Notatka",
          sets: "Serie",
          workouts: "Treningi",
          muscles: "Grupy mięśni",
          date: "Data",
          select: "Wybierz ćwiczenie",
        },
        profile: {
          showRecords: "Zobacz rekordy",
          addRoutine: "Dodaj rutynę",
          showAchievements: "Zobacz osiągnięcia",
          addNewGoal: "Dodaj nowy cel",
          showGoals: "Zobacz cele",
          removeRoutine: "Usuń rutynę",
          allTrainings: "Wszystkie treningi",
          daysWithUs: "Dni z nami",
          email: "Email",
          birth: "Data urodzenia",
          social: "Społeczność",
        },
        dashboard: {
          title: "TRENUJ CODZIENNIE",
          description:
            "Codzienne treningi to nie tylko sposób na poprawę sylwetki, ale przede wszystkim na utrzymanie zdrowego stylu życia. Trenując regularnie, wzmacniasz swoje ciało, zwiększasz wytrzymałość i poprawiasz samopoczucie. Niezależnie od tego, czy jesteś początkujący, czy zaawansowany, każdy dzień treningu przybliża Cię do osiągnięcia wymarzonych wyników. Zbuduj nawyk, który zmieni Twoje życie i ciesz się korzyściami, jakie niesie aktywność!",
          newTraining: "Nowy trening",
          historyTraining: "Historia treningów",
          stats: "Statystyki",
          friends: "Znajomi",
        },
        historyTraining: {
          filtration: "Filtracja",
          category: "Kategoria",
          noTrainings: "Brak dostępnych treningów",
          addTraining: "Dodaj trening",
          all: "Wszytskie",
          loadMore: "Załaduj więcej",
          startDate: "Data rozpoczęcia",
          endDate: "Data zakończenia",
        },
        newTraining: {
          plannedWorkout: "Planowany trening",
          quickWorkout: "Szybki trening",
          doWorkout: "Zrób trening",
          avaiableExercises: "Dostępne Ćwiczenia",
          selectedExercises: "Wybrane Ćwiczenia",
          noExercises: "Brak wybranych ćwiczeń",
          searchExercise: "Wyszukaj ćwiczenie",
          nextStep: "Następny krok",
          summary: "Podsumowanie",
          addSet: "Dodaj serię",
          removeSet: "Usuń serię",
          saveWorkout: "Zapisz trening",
          notePlaceholder: "Najlepszy trening w życiu...",
        },
        chart: {
          noData: "Brak danych do wyświetlenia",
          durationTitle: "Czas trwania ostatnich treningów",
          muscleUsed: "Zaangażowane grupy mięśniowe",
          rating: "Histogram ocen treningów",
          setsTitle: "Ilość serii w ostatnich treningach",
          volumeTitle: "Objętość w ostatnich treningach",
          mostReps: "Najwięcej powtórzeń w serii",
          heaviestWeight: "Najcięższy ciężar w serii",
        },
        stats: {
          personalStats: "Statystyki osobiste",
          totalVolume: "Łączna objętość",
          workoutSessions: "Sesje treningowe",
          totalSets: "Łączna liczba serii",
          totalTime: "Łączny czas",
          week: "Tydzień",
          month: "Miesiąć",
          year: "Rok",
          all: "Wszystko",
        },
        routine: {
          newRoutine: "Stwórz nową rutynę",
          name: "Nazwa rutyny",
          save: "Zapisz rutynę",
          avaiableExercises: "Dostępne Ćwiczenia",
          selectedExercises: "Wybrane Ćwiczenia",
          noExercises: "Brak wybranych ćwiczeń",
          searchExercise: "Wyszukaj ćwiczenie",
        },
        goals: {
          title: "Osiągnij swoje cele siłowe",
          description:
            "Odkryj swoją siłę dzięki naszemu przewodnikowi po treningu siłowym. Niezależnie od tego, czy chcesz zwiększyć masę mięśniową, poprawić siłę, czy zbudować wymarzoną sylwetkę, ten przewodnik dostarcza narzędzi i strategii, które pomogą ci osiągnąć sukces. Dowiedz się, jak tworzyć efektywne plany treningowe, unikać kontuzji i utrzymać motywację. Pełen wiedzy ekspertów, inspirujących historii i praktycznych wskazówek, ten przewodnik pomoże ci wyznaczać cele, przełamywać bariery i cieszyć się rezultatami. Zrób pierwszy krok na swojej siłowej drodze już dziś!",
          createdDate: "Data utworzenia: ",
          finishDate: "Data zakończenia: ",
          noGoals: "Brak dostępnych celów do wyświetlenia",
          addGoal: "Dodaj nowe cele",
          completed: "Zakończony",
          notCompleted: "Niezakończony",
          inProgress: "W trakcie",
          dayLeft: "DZIEŃ POZOSTAŁ",
          daysLeft: "DNI POZOSTAŁO",
        },
      },
    },
  },
  gb: {
    translation: {
      shared: {
        achievements: {
          social:"Social Achievements",
          first_circle_title: "First Circle",
          first_circle_description:
            "You've reached 5 friends! Building connections is a great start.",
          growing_network_title: "Growing Network",
          growing_network_description:
            "15 friends and counting! Your social network is expanding.",
          community_builder_title: "Community Builder",
          community_builder_description:
            "With 50 friends, you’re connecting with a vibrant community!",
          social_star_title: "Social Star",
          social_star_description:
            "You've hit 100 friends! Your connections keep growing—keep it up!",
          network_champion_title: "Network Champion",
          network_champion_description:
            "250 friends! You're a true social connector and inspiration to others.",
        },
        navbar: {
          lightMode: "Light Mode",
          nightMode: "Night Mode",
          logIn: "Log In",
          logout: "Logout",
        },
        friends: {
          friends: "Friends",
          addFriend: "Add Friend",
          joinedPlatform: "Joined platform",
          usersToInvite: "Users To Invite",
          pendingUsers: "Pending Users",
          noFriends: "No friends available to display",
          noPending: "No pending users available to display",
          sendInvitation: "Send invitation",
          newFriend: "Search users",
          invitationText: "want to be your friend. Do you accept?",
          accept: "Accept",
          reject: "Reject",
        },
        profile: {
          removeFriend: "Remove Friend",
          daysOnPlatform: "Days on platform",
          birth: "Birth date",
        },
        actions: {
          delete: "Delete",
          edit: "Edit",
          close: "Close",
          add: "Add",
        },
        login: {
          loginTitle: "Login",
          loginButton: "Login",
          email: "Email",
          password: "Password",
          noAccount: "I have no account",
          users: "Users on our <br/> platform",
          backgroundText: "TRAIN AND RUN",
        },
        register: {
          registerTitle: "Register",
          registerButton: "Register",
          email: "Email",
          password: "Password",
          account: "I have an account",
          birth: "Birth date",
          profilePhoto: " Profile photo",
          nickname: "Nickname",
          description: "Description",
          noFile: "No file",
        },
        chart: {
          run: "Run",
          gym: "Gym",
          trainings: "Trainings",
          months: "Months",
          workouts: "Trainings in recent months",
        },
        posts: {
          lastPosts: "Recent user posts",
          noPosts: "No posts to display",
          new: "New",
        },
      },
      run: {
        achievements: {
          run:"Run Achievements",
          marathon_achiever_title: "Marathon Achiever",
          marathon_achiever_description: "You have completed a marathon!",
          ultra_runner_title: "Ultra Runner",
          ultra_runner_description: "You have completed an ultra marathon!",
          endurance_master_title: "Endurance Master",
          endurance_master_description: "You have reached 1000 kilometers!",
          long_distance_veteran_title: "Long Distance Veteran",
          long_distance_veteran_description:
            "You have reached 5000 kilometers!",
          distance_legend_title: "Distance Legend",
          distance_legend_description: "You have reached 10,000 kilometers!",
        },
        categories:{
          long_run:"Long Run",
          easy_run:"Easy Run",
          interval:"Interval",
          regeneration:"Regeneration"
        },
        general: {
          averagePulse: "Average pulse",
          distance: "Distance",
          duration: "Duration",
          rating: "Rating",
          note: "Note",
          type: "Type",
          trainings: "Trainings",
          category: "Categories",
        },
        dashboard: {
          title: "RUN EVERYDAY",
          description:
            "Running is not only a great way to improve your fitness, but also to relax your mind. Every step you take strengthens your body, boosts your heart’s performance, and lifts your mood. Whether you run for health, fitness, or simply for enjoyment, regular running contributes to overall well-being. You don’t need specialized equipment – just a comfortable pair of shoes and some motivation. Take the first step and join those who have discovered the joy of running!",
          newTraining: "New training",
          historyTraining: "History training",
          stats: "Stats",
          friends: "Friends",
        },
        profile: {
          showRecords: "Show records",
          addCategory: "Add category",
          showAchievements: "Show achievements",
          addNewGoal: "Add new goal",
          showGoals: "Show goals",
          removeRoutine: "Remove routine",
          allTrainings: "All workouts",
          daysWithUs: "Days with us",
          email: "Email",
          birth: "Date of birth",
          social: "Social",
        },
        historyTraining: {
          filtration: "Filtration",
          category: "Category",
          title: "Regular strength training",
          description:
            "Regular strength training is essential for improving physical fitness and health. It strengthens muscles, enhances bone density, and supports metabolism. Consistent exercise boosts overall endurance, helps maintain a healthy weight, and reduces the risk of various diseases. A well-balanced workout plan should include a variety of exercises to engage all muscle groups and ensure balanced development.",
          noTrainings: "No available trainings",
          addTraining: "Add training",
          all: "All",
          loadMore: "Load more",
          startDate: "Start Date",
          endDate: "End Date",
        },
        newTraining: {
          doTraining: "Do training",
        },
        goals: {
          title: "Achieve Your Running Goals",
          description:
            "Unlock your full potential as a runner with our ultimate guide to achieving your running goals. Whether you're aiming to complete your first 5K, conquer a marathon, or improve your personal best, this guide provides you with the tools and strategies you need. Learn how to create an effective training plan, stay motivated, and overcome challenges with confidence. Packed with expert advice, inspiring stories, and practical tips, this guide will help you cross the finish line and celebrate your running achievements. Take the first step towards your running success today!",
          createdDate: "Created Date: ",
          finishDate: "Finish Date: ",
          noGoals: "No goals available to display",
          addGoal: "Add new goals",
          completed: "Completed",
          notCompleted: "Not Completed",
          inProgress: "In Progress",
          dayLeft: "DAY LEFT",
          daysLeft: "DAYS LEFT",
        },
        records: {
          personalRecords: "Personal Records",
          longestDistance: "Longest Distance",
          highestAveragePulse: "Highest Average Pulse",
          longestTraining: "Longest Training",
        },
        stats: {
          personalStats: "Personal Stats",
          totalDistance: "Total Distance",
          averageRating: "Average Rating",
          averagePulse: "Average Pulse",
          totalTime: "Total Time",
          week: "Week",
          month: "Month",
          year: "Year",
          all: "All",
        },
        chart: {
          noData: "No data available",
          averagePulseTitle: "Average heart rates from recent workouts",
          distanceTitle: "Distance from recent workouts",
          durationTitle: "Duration of Recent Workouts",
          ratingTitle: "Training Ratings Histogram",
          runTypesTitle: "Types of runs during recent workouts",
        },
      },
      gym: {
        exercises: {
          bench_press: "Bench Press",
          chest_dips: "Chest Dips",
          chin_ups: "Chin Ups",
          bent_over_barbell_row: "Bent Over Barbell Row",
          barbell_biceps_curl: "Barbell Biceps Curl",
          dumbbell_biceps_curl: "Dumbbell Biceps Curl",
          cable_triceps_pushdown: "Cable Triceps Pushdown",
          face_pull: "Face Pull",
          barbell_back_squat: "Barbell Back Squat",
          sit_ups: "Sit Ups",
          lat_pulldown: "Lat Pulldown",
          dumbbell_lateral_raise: "Dumbbell Lateral Raise",
          incline_dumbbell_curl: "Incline Dumbbell Curl",
          triceps_cable_kick_back: "Triceps Cable Kick-Back",
          dumbbell_bench_press: "Dumbbell Bench Press",
          incline_dumbbell_bench_press: "Incline Dumbbell Bench Press",
          incline_bench_press: "Incline Bench Press",
          dumbbell_flys: "Dumbbell Flys",
          butterfly_machine: "Butterfly Machine",
          standing_cable_fly: "Standing Cable Fly",
          push_up: "Push Up",
          seated_cable_row: "Seated Cable Row",
          pull_up: "Pull Up",
          standing_dumbbell_curl: "Standing Dumbbell Curl",
          standing_hammer_curl: "Standing Hammer Curl",
          spider_curl: "Spider Curl",
          dumbbell_french_press: "Dumbbell French Press",
          straight_bar_tricep_extension: "Straight Bar Tricep Extension",
          leg_extension: "Leg Extension",
          dumbbell_bulgarian_split_squat: "Dumbbell Bulgarian Split Squat",
          dumbbell_romanian_deadlift: "Dumbbell Romanian Deadlift",
          walking_lunges: "Walking Lunges",
        },
        categories: {
          All: "All",
          Chest: "Chest",
          Back: "Back",
          Biceps: "Biceps",
          Triceps: "Triceps",
          Shoulders: "Shoulders",
          Abs: "Abs",
          Legs: "Legs",
        },
        achievements: {
          gym:"Gym Achievements",
          first_steps_title: "First Steps",
          first_steps_description:
            "Congratulations on completing 5 workouts! Every journey begins with a single step, and you've taken the first few. Keep up the momentum!",
          building_the_habit_title: "Building the Habit",
          building_the_habit_description:
            "Amazing work! You've completed 20 workouts, showing dedication and commitment. You're building a strong habit — keep pushing forward!",
          centurion_title: "Centurion",
          centurion_description:
            "Incredible achievement! With 100 workouts under your belt, you're a true fitness warrior. Your hard work and perseverance are inspiring!",
        },
        general: {
          page: "Page",
          next: "Next",
          previous: "Previous",
          reps: "Reps",
          weight: "Weight",
          volume: "Volume",
          duration: "Duration",
          rating: "Rating",
          note: "Note",
          sets: "Sets",
          workouts: "Workouts",
          muscles: "Muscle used",
          date: "Date",
          select: "Select exercise",
        },
        profile: {
          showRecords: "Show records",
          addRoutine: "Add routine",
          showAchievements: "Show achievements",
          addNewGoal: "Add new goal",
          showGoals: "Show goals",
          removeRoutine: "Remove routine",
          allTrainings: "All workouts",
          daysWithUs: "Days with us",
          email: "Email",
          birth: "Date of birth",
          social: "Social",
        },
        dashboard: {
          title: "TRAINING EVERYDAY",
          description:
            "Daily workouts are not just a way to improve your physique, but also to maintain a healthy lifestyle. By training regularly, you strengthen your body, boost endurance, and enhance your overall well-being. Whether you're a beginner or advanced, each day of training brings you closer to achieving your dream results. Build a habit that transforms your life and enjoy the benefits of daily activity!",
          newTraining: "New training",
          historyTraining: "History training",
          stats: "Stats",
          friends: "Friends",
        },
        historyTraining: {
          filtration: "Filtration",
          category: "Category",
          title: "Regular strength training",
          description:
            "Regular strength training is essential for improving physical fitness and health. It strengthens muscles, enhances bone density, and supports metabolism. Consistent exercise boosts overall endurance, helps maintain a healthy weight, and reduces the risk of various diseases. A well-balanced workout plan should include a variety of exercises to engage all muscle groups and ensure balanced development.",
          noTrainings: "No available trainings",
          addTraining: "Add training",
          all: "All",
          loadMore: "Load more",
          startDate: "Start Date",
          endDate: "End Date",
        },
        newTraining: {
          plannedWorkout: "Planned Workout",
          quickWorkout: "Quick Workout",
          doWorkout: "Do Workout",
          avaiableExercises: "Avaiable Exercises",
          selectedExercises: "Selected Exercises",
          noExercises: "No exercises selected",
          searchExercise: "Search exercise",
          nextStep: "Next step",
          summary: "Summary",
          addSet: "Add set",
          removeSet: "Remove set",
          saveWorkout: "Save workout",
          notePlaceholder: "Best run ever...",
        },
        chart: {
          noData: "No Data to Display",
          durationTitle: "Duration of Recent Workouts",
          muscleUsed: "Muscle Groups Used",
          rating: "Training Ratings Histogram",
          setsTitle: "Number of Sets in Recent Workouts",
          volumeTitle: "Volume in Recent Workouts",
          mostReps: "Most Reps in a Set",
          heaviestWeight: "Heaviest Weight in a Set",
        },
        stats: {
          personalStats: "Personal Stats",
          totalVolume: "Total Volume",
          workoutSessions: "Workout Sessions",
          totalSets: "Total Sets",
          totalTime: "Total Time",
          week: "Week",
          month: "Month",
          year: "Year",
          all: "All",
        },
        routine: {
          newRoutine: "Create new routine",
          name: "Routine name",
          save: "Save routine",
          avaiableExercises: "Avaiable Exercises",
          selectedExercises: "Selected Exercises",
          noExercises: "No exercises selected",
          searchExercise: "Search exercise",
        },
        goals: {
          title: "Achieve Your Strength Goals",
          description:
            "Unlock your strength with our guide to strength training. Whether you aim to build muscle mass, improve strength, or achieve your dream physique, this guide provides the tools and strategies to help you succeed. Learn how to create effective training plans, avoid injuries, and stay motivated. Packed with expert knowledge, inspiring stories, and practical tips, this guide will help you set goals, overcome challenges, and enjoy the results. Take the first step on your strength journey today!",
          createdDate: "Created Date: ",
          finishDate: "Finish Date: ",
          noGoals: "No available goals to display",
          addGoal: "Add New Goals",
          completed: "Completed",
          notCompleted: "Not Completed",
          inProgress: "In Progress",
          dayLeft: "DAY LEFT",
          daysLeft: "DAYS LEFT",
        },
      },
    },
  },
};

const localStorageKey = "selectedLanguage";

const getDefaultLanguage = () => {
  const selectedLanguage = localStorage.getItem(localStorageKey);
  if (selectedLanguage) {
    return selectedLanguage;
  } else {
    return "pl";
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: getDefaultLanguage(),
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export const setLanguage = (language) => {
  i18n.changeLanguage(language);
  localStorage.setItem(localStorageKey, language);
};

export default i18n;
